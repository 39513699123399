import {StargateClient} from '@cosmjs/stargate';
import {ChainWalletBase} from '@cosmos-kit/core';
import {isValidEndpoint} from '../../utils/endpoints';

export class ChainKeplrExtension extends ChainWalletBase {
  getRpcEndpoint = async (): Promise<string | undefined> => {
    if (this._rpcEndpoint && (await isValidEndpoint(this._rpcEndpoint))) {
      return this._rpcEndpoint;
    }
    for (const endpoint of this.rpcEndpoints || []) {
      if (await isValidEndpoint(endpoint)) {
        this._rpcEndpoint = endpoint;
        return endpoint;
      }
    }
    console.warn(`No valid RPC endpoint available!`);
    return undefined;
  };

  getStargateClient = async (): Promise<StargateClient | undefined> => {
    const rpcEndpoint = await this.getRpcEndpoint();

    if (this.offlineSigner && rpcEndpoint) {
      console.info('Using RPC endpoint ' + rpcEndpoint);
      return StargateClient.connect(rpcEndpoint, this.stargateOptions);
    }
    console.error('Undefined offlineSigner or rpcEndpoint.');
    return undefined;
  };
}
