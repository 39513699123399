import {MainWalletBase} from '@cosmos-kit/core';
import type {ChainRecord, EndpointOptions, Wallet} from '@cosmos-kit/core';

import {ChainKeplrExtension} from './ChainKeplrExtension';
import {keplrExtensionInfo} from './registry';
import {getKeplrFromExtension} from './utils/getKeplrFromExtension';
import {KeplrClient} from './KeplrClient';

/**
 * Represents main wallet.
 * It has access to each supported chain wallet object.
 */
export class KeplrExtensionWallet extends MainWalletBase {
  constructor(walletInfo: Wallet = keplrExtensionInfo, preferredEndpoints?: EndpointOptions) {
    super(walletInfo, ChainKeplrExtension);
    this.preferredEndpoints = preferredEndpoints;
  }

  setChains(chains: ChainRecord[]): void {
    this._chainWallets = new Map(
      chains.map(chain => {
        chain.preferredEndpoints = {
          rpc: [
            ...(chain.preferredEndpoints?.rpc || []),
            ...(this.preferredEndpoints?.[chain.name]?.rpc || []),
            ...(chain.chain?.apis?.rpc?.map(e => e.address) || []),
          ],
          rest: [
            ...(chain.preferredEndpoints?.rest || []),
            ...(this.preferredEndpoints?.[chain.name]?.rest || []),
            ...(chain.chain?.apis?.rest?.map(e => e.address) || []),
          ],
        };

        return [chain.name, new this.ChainWallet(this.walletInfo, chain)];
      })
    );

    this.onSetChainsDone();
  }

  async signArbitrary(chainId: string, address: string, data: string | Uint8Array) {
    const keplrClient = await this.fetchClient();
    if (!keplrClient) {
      console.error('No Keplr client found');
      return;
    }
    return await keplrClient.client.signArbitrary(chainId, address, data);
  }

  async fetchClient() {
    try {
      const keplr = await getKeplrFromExtension();
      if (keplr) {
        keplr.defaultOptions = {
          sign: {preferNoSetFee: true, preferNoSetMemo: true, disableBalanceCheck: true},
        };
        return new KeplrClient(keplr);
      }
      return undefined;
    } catch (error) {
      this.setClientNotExist();
      return undefined;
    }
  }
}
