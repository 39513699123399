export async function isValidEndpoint(endpoint: string) {
  console.info(`Testing accessibility of ${endpoint}`);
  try {
    const response = await fetch(endpoint);
    if (response.status == 200) {
      console.info('Access successfully.');
      return true;
    }
  } catch (err) {}
  console.info('Access failed.');
  return false;
}
